/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/**
 * base64 encoder and decoder
 * Copied and adapted from https://github.com/protobufjs/protobuf.js/blob/master/lib/base64/index.js
 */
// Base64 encoding table
var b64 = new Array(64);
// Base64 decoding table
var s64 = new Array(123);
// 65..90, 97..122, 48..57, 43, 47
for (var i = 0; i < 64;)
    s64[b64[i] = i < 26 ? i + 65 : i < 52 ? i + 71 : i < 62 ? i - 4 : i - 59 | 43] = i++;
export function b64Encode(buffer, start, end) {
    var parts = null;
    var chunk = [];
    var i = 0, // output index
    j = 0, // goto index
    t; // temporary
    while (start < end) {
        var b = buffer[start++];
        switch (j) {
            case 0:
                chunk[i++] = b64[b >> 2];
                t = (b & 3) << 4;
                j = 1;
                break;
            case 1:
                chunk[i++] = b64[t | b >> 4];
                t = (b & 15) << 2;
                j = 2;
                break;
            case 2:
                chunk[i++] = b64[t | b >> 6];
                chunk[i++] = b64[b & 63];
                j = 0;
                break;
        }
        if (i > 8191) {
            (parts || (parts = [])).push(String.fromCharCode.apply(String, chunk));
            i = 0;
        }
    }
    if (j) {
        chunk[i++] = b64[t];
        chunk[i++] = 61;
        if (j === 1)
            chunk[i++] = 61;
    }
    if (parts) {
        if (i)
            parts.push(String.fromCharCode.apply(String, chunk.slice(0, i)));
        return parts.join("");
    }
    return String.fromCharCode.apply(String, chunk.slice(0, i));
}
var invalidEncoding = "invalid encoding";
export function b64Decode(s) {
    var buffer = [];
    var offset = 0;
    var j = 0, // goto index
    t; // temporary
    for (var i = 0; i < s.length;) {
        var c = s.charCodeAt(i++);
        if (c === 61 && j > 1)
            break;
        if ((c = s64[c]) === undefined)
            throw Error(invalidEncoding);
        switch (j) {
            case 0:
                t = c;
                j = 1;
                break;
            case 1:
                buffer[offset++] = t << 2 | (c & 48) >> 4;
                t = c;
                j = 2;
                break;
            case 2:
                buffer[offset++] = (t & 15) << 4 | (c & 60) >> 2;
                t = c;
                j = 3;
                break;
            case 3:
                buffer[offset++] = (t & 3) << 6 | c;
                j = 0;
                break;
        }
    }
    if (j === 1)
        throw Error(invalidEncoding);
    return new Uint8Array(buffer);
}
function b64Test(s) {
    return /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/.test(s);
}
export function replacer(key, value) {
    if (value && value.constructor === Uint8Array) {
        return b64Encode(value, 0, value.length);
    }
    return value;
}
export function fetchReq(path, init) {
    var _a = init || {}, pathPrefix = _a.pathPrefix, req = __rest(_a, ["pathPrefix"]);
    var url = pathPrefix ? "".concat(pathPrefix).concat(path) : path;
    return fetch(url, req).then(function (r) { return r.json().then(function (body) {
        if (!r.ok) {
            throw body;
        }
        return body;
    }); });
}
/**
 * fetchStreamingRequest is able to handle grpc-gateway server side streaming call
 * it takes NotifyStreamEntityArrival that lets users respond to entity arrival during the call
 * all entities will be returned as an array after the call finishes.
 **/
export function fetchStreamingRequest(path, callback, init) {
    return __awaiter(this, void 0, void 0, function () {
        var _a, pathPrefix, req, url, result, resp, errMsg;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = init || {}, pathPrefix = _a.pathPrefix, req = __rest(_a, ["pathPrefix"]);
                    url = pathPrefix ? "".concat(pathPrefix).concat(path) : path;
                    return [4 /*yield*/, fetch(url, req)
                        // needs to use the .ok to check the status of HTTP status code
                        // http other than 200 will not throw an error, instead the .ok will become false.
                        // see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#
                    ];
                case 1:
                    result = _b.sent();
                    if (!!result.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, result.json()];
                case 2:
                    resp = _b.sent();
                    errMsg = resp.error && resp.error.message ? resp.error.message : "";
                    throw new Error(errMsg);
                case 3:
                    if (!result.body) {
                        throw new Error("response doesnt have a body");
                    }
                    return [4 /*yield*/, result.body
                            .pipeThrough(new TextDecoderStream())
                            .pipeThrough(getNewLineDelimitedJSONDecodingStream())
                            .pipeTo(getNotifyEntityArrivalSink(function (e) {
                            if (callback) {
                                callback(e);
                            }
                        }))
                        // wait for the streaming to finish and return the success respond
                    ];
                case 4:
                    _b.sent();
                    // wait for the streaming to finish and return the success respond
                    return [2 /*return*/];
            }
        });
    });
}
/**
 * getNewLineDelimitedJSONDecodingStream returns a TransformStream that's able to handle new line delimited json stream content into parsed entities
 */
function getNewLineDelimitedJSONDecodingStream() {
    return new TransformStream({
        start: function (controller) {
            controller.buf = '';
            controller.pos = 0;
        },
        transform: function (chunk, controller) {
            if (controller.buf === undefined) {
                controller.buf = '';
            }
            if (controller.pos === undefined) {
                controller.pos = 0;
            }
            controller.buf += chunk;
            while (controller.pos < controller.buf.length) {
                if (controller.buf[controller.pos] === '\n') {
                    var line = controller.buf.substring(0, controller.pos);
                    var response = JSON.parse(line);
                    controller.enqueue(response.result);
                    controller.buf = controller.buf.substring(controller.pos + 1);
                    controller.pos = 0;
                }
                else {
                    ++controller.pos;
                }
            }
        }
    });
}
/**
 * getNotifyEntityArrivalSink takes the NotifyStreamEntityArrival callback and return
 * a sink that will call the callback on entity arrival
 * @param notifyCallback
 */
function getNotifyEntityArrivalSink(notifyCallback) {
    return new WritableStream({
        write: function (entity) {
            notifyCallback(entity);
        }
    });
}
/**
 * Checks if given value is a plain object
 * Logic copied and adapted from below source:
 * https://github.com/char0n/ramda-adjunct/blob/master/src/isPlainObj.js
 * @param  {unknown} value
 * @return {boolean}
 */
function isPlainObject(value) {
    var isObject = Object.prototype.toString.call(value).slice(8, -1) === "Object";
    var isObjLike = value !== null && isObject;
    if (!isObjLike || !isObject) {
        return false;
    }
    var proto = Object.getPrototypeOf(value);
    var hasObjectConstructor = typeof proto === "object" &&
        proto.constructor === Object.prototype.constructor;
    return hasObjectConstructor;
}
/**
 * Checks if given value is of a primitive type
 * @param  {unknown} value
 * @return {boolean}
 */
function isPrimitive(value) {
    return ["string", "number", "boolean"].some(function (t) { return typeof value === t; });
}
/**
 * Checks if given primitive is zero-value
 * @param  {Primitive} value
 * @return {boolean}
 */
function isZeroValuePrimitive(value) {
    return value === false || value === 0 || value === "";
}
/**
 * Flattens a deeply nested request payload and returns an object
 * with only primitive values and non-empty array of primitive values
 * as per https://github.com/googleapis/googleapis/blob/master/google/api/http.proto
 * @param  {RequestPayload} requestPayload
 * @param  {String} path
 * @return {FlattenedRequestPayload>}
 */
function flattenRequestPayload(requestPayload, path) {
    if (path === void 0) { path = ""; }
    return Object.keys(requestPayload).reduce(function (acc, key) {
        var _a;
        var value = requestPayload[key];
        var newPath = path ? [path, key].join(".") : key;
        var isNonEmptyPrimitiveArray = Array.isArray(value) &&
            value.every(function (v) { return isPrimitive(v); }) &&
            value.length > 0;
        var isNonZeroValuePrimitive = isPrimitive(value) && !isZeroValuePrimitive(value);
        var objectToMerge = {};
        if (isPlainObject(value)) {
            objectToMerge = flattenRequestPayload(value, newPath);
        }
        else if (isNonZeroValuePrimitive || isNonEmptyPrimitiveArray) {
            objectToMerge = (_a = {}, _a[newPath] = value, _a);
        }
        return __assign(__assign({}, acc), objectToMerge);
    }, {});
}
/**
 * Renders a deeply nested request payload into a string of URL search
 * parameters by first flattening the request payload and then removing keys
 * which are already present in the URL path.
 * @param  {RequestPayload} requestPayload
 * @param  {string[]} urlPathParams
 * @return {string}
 */
export function renderURLSearchParams(requestPayload, urlPathParams) {
    if (urlPathParams === void 0) { urlPathParams = []; }
    var flattenedRequestPayload = flattenRequestPayload(requestPayload);
    var urlSearchParams = Object.keys(flattenedRequestPayload).reduce(function (acc, key) {
        // key should not be present in the url path as a parameter
        var value = flattenedRequestPayload[key];
        if (urlPathParams.find(function (f) { return f === key; })) {
            return acc;
        }
        return Array.isArray(value)
            ? __spreadArray(__spreadArray([], acc, true), value.map(function (m) { return [key, m.toString()]; }), true) : (acc = __spreadArray(__spreadArray([], acc, true), [[key, value.toString()]], false));
    }, []);
    return new URLSearchParams(urlSearchParams).toString();
}
